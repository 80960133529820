import { Swiper, Navigation, Pagination, Autoplay } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination, Autoplay]);

export default () => {
    var swiper = new Swiper(".banner-default", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoplay: {
            delay: 5000,
        },
    });

    var swiperG = new Swiper(".gallery-modal-slider", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    var swiperO = new Swiper(".banner-our-solutions", {
        slidesPerView: 4,
        spaceBetween: 10,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
        },
        navigation: {
            nextEl: ".swiper-button-next.so",
            prevEl: ".swiper-button-prev.so",
        },
    });

    var swiperP = new Swiper(".partners-slide", {
        slidesPerView: 5,
        spaceBetween: 10,
        // init: false,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
        },
        navigation: {
            nextEl: ".swiper-button-next.pa",
            prevEl: ".swiper-button-prev.pa",
        },
    });

    let timelineSlide = new Swiper(".timeline__mobile", {
        watchOverflow: true,
        slidesPerView: 2.2,
        breakpoints: {
            720: {
                slidesPerView: 1.2,
            },
        },
    });

    // let mygallery = new Swiper(".mygallery", {
    //     loop: true,
    //     spaceBetween: 10,
    //     slidesPerView: 4,
    //     freeMode: true,
    //     watchSlidesProgress: true,
    // });

    let swiper1 = new Swiper(".myGallery", {
        spaceBetween: 10,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesProgress: true,
    });

    let swiper2 = new Swiper(".myGallery2", {
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next.s",
            prevEl: ".swiper-button-prev.s",
        },
        thumbs: {
            swiper: swiper1,
        },
    });

    var swiperDepositions = new Swiper(".depositions-slide", {
        slidesPerView: 2,
        spaceBetween: 10,
        // init: false,
        autoplay: {
            delay: 9000,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
        },
        navigation: {
            nextEl: ".swiper-button-next.dp",
            prevEl: ".swiper-button-prev.dp",
        },
    });

    let ratesSlide = new Swiper(".bar-info-enterprises-slide", {
        loop: true,
        autoplay: {
            delay: "auto",
            disableOnInteraction: false,
        },
        slidesPerView: 5,
        speed: 7000,
        grabCursor: true,
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 5,
                spaceBetween: 0,
            },
        },
        navigation: {
            nextEl: ".bar-info-enterprises-button-next",
            prevEl: ".bar-info-enterprises-button-prev",
        },
    });

    let slideCases = new Swiper(".cases-solutions", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoplay: {
            delay: 5000,
        },
    });
};
