import burger from "./modules/burger";
import FixedElementClearance from "./modules/FixedElementClearance";
import LazyLoad from "vanilla-lazyload";
import MenuToggle from "./modules/MenuToggle";
import SmoothScroll from "smooth-scroll/dist/smooth-scroll";
import search from "./modules/search";
import slides from "./modules/slides";
import header from "./modules/header";
import accordion from "./modules/accordion";
import menuControl from "./modules/menu-control";
import initModal from "./modules/initModal";
import contador from "./modules/contador";
import swiperGallery from "./modules/swiper-gallery";
import fancyBox from "./modules/fancy";
// import formHome from "./modules/form-home";
import formHome from "./modules/form-home";
import mask from "./modules/mask";
import selectTwo from "./modules/select-two";

export default () => {
    new LazyLoad({ elements_selector: ".lazy" });

    new SmoothScroll('a[href*="#"]', {
        header: ".main-header-bundle",
        speed: 1500,
        speedAsDuration: true,
        easing: "easeOutQuint",
    });

    new FixedElementClearance({
        element: ".js-get-main-header-height",
        CSSVariableName: "main-header-height",
    });

    new MenuToggle({
        menuToggle: [".js-main-menu-mobile-toggle"],
        menuElement: ".main-menu-mobile",
        menuClose: [".js-close-menu-mobile"],
        activeBodyClass: "mobile-menu-is-open",
        breakpointToHide: 1200,
    });

    accordion();
    menuControl();
    slides();
    burger();
    search();
    header();
    accordion();
    menuControl();
    initModal();
    contador();
    swiperGallery();
    fancyBox();
    formHome();
    mask();
    selectTwo();
};
