export default function mask() {
    let phones = document.querySelectorAll("#phone");
    let dates = document.querySelectorAll("#nascimento");
    let ceps = document.querySelectorAll("#cep");
    let cpfs = document.querySelectorAll("#cpf");
    let moneys = document.querySelectorAll("#rendafamiliar");
    let minValues = document.querySelectorAll("#minValue");
    let maxValues = document.querySelectorAll("#maxValue");
    let minValuesSale = document.querySelectorAll("#minValueS");
    let maxValuesSale = document.querySelectorAll("#maxValueS");

    phones.forEach(phone => {
        $(phone).mask("(00) 00000-0000");
    });

    dates.forEach(date => {
        $(date).mask("00/00/0000");
    });

    ceps.forEach(cep => {
        $(cep).mask("00000-000");
    });

    cpfs.forEach(cpf => {
        $(cpf).mask("000.000.000-00", { reverse: true });
    });

    moneys.forEach(money => {
        $(money).mask("000.000.000.000.000,00", { reverse: true });
    });

    minValues.forEach(min => {
        $(min).mask("000.000.000.000.000,00", { reverse: true });
    });

    maxValues.forEach(max => {
        $(max).mask("000.000.000.000.000,00", { reverse: true });
    });

    minValuesSale.forEach(minSale => {
        $(minSale).mask("000.000.000.000.000,00", { reverse: true });
    });

    maxValuesSale.forEach(maxSale => {
        $(maxSale).mask("000.000.000.000.000,00", { reverse: true });
    });
}
