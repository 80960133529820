function setupModals() {
    const modalOpeners = document.querySelectorAll("[data-modal-open]");

    modalOpeners.forEach(opener => {
        opener.addEventListener("click", () => {
            const modalId = opener.dataset.modalOpen;
            const modal = document.querySelector(`[data-modal="${modalId}"]`);
            if (modal) {
                modal.classList.add("is-visible");
            }
        });
    });

    const modalClosers = document.querySelectorAll(".js-modal-close");

    modalClosers.forEach(closer => {
        closer.addEventListener("click", () => {
            const modal = closer.closest(".modal");
            if (modal) {
                modal.classList.remove("is-visible");
            }
        });
    });
}

setupModals();
