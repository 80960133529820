export default function formHome() {
    $(".menu-toggle-button, .menu-toggle-button.range").on("click", function() {
        event.preventDefault();
        let navigationMenu = $(this).next(".navigation-menu");
        navigationMenu.toggleClass("active");
    });

    let minAmountValue = $("#minValue")[0];
    let maxAmountValue = $("#maxValue")[0];

    $(".tab-header-item").on("click", function() {
        minAmountValue = $(".tab-content-item.is-active #minValue")[0];
        maxAmountValue = $(".tab-content-item.is-active #maxValue")[0];

        $(".range").html(
            'Valor inicial - final <i class="fa fa-angle-down" aria-hidden="true"></i>'
        );
    });

    $(".aplicar").on("click", function(e) {
        e.preventDefault();

        let combinedValue = `R$ ${minAmountValue.value} - R$ ${maxAmountValue.value}`;

        let menuToggleButton = $(".range");

        menuToggleButton.text(combinedValue);
        $(this)
            .closest(".navigation-menu")
            .removeClass("active");
    });

    if (window.innerWidth > 1200) {
        $(document).on("click", function(event) {
            if (
                !$(event.target).closest(".navigation-menu").length &&
                !$(event.target).closest(".menu-filter").length
            ) {
                $(".navigation-menu").removeClass("active");
            }
        });

        $(".menu-toggle-button").on("click", function() {
            $(".navigation-menu").removeClass("active");
            $(this)
                .parent()
                .find(".navigation-menu")
                .addClass("active");
        });

        function getDefaultValue(element) {
            return parseInt($(element).data("default-value"));
        }

        function getMinValue(element) {
            return parseInt($(element).data("min-value"));
        }

        function getMaxValue(element) {
            return parseInt($(element).data("max-value"));
        }

        $(function() {
            $(".slider-range-min").slider({
                range: "min",
                value: getDefaultValue(".slider-range-min"),
                min: getMinValue(".slider-range-min"),
                max: getMaxValue(".slider-range-min"),
                slide: function(event, ui) {
                    $(".minAmount").text("R$ " + ui.value);
                },
            });

            $(".slider-range-max").slider({
                range: "min",
                value: getDefaultValue(".slider-range-max"),
                min: getMinValue(".slider-range-max"),
                max: getMaxValue(".slider-range-max"),
                slide: function(event, ui) {
                    $(".maxAmount").text("R$ " + ui.value);
                },
            });

            $(".minAmount").text("R$ " + $(".slider-range-min").slider("value"));
            $(".maxAmount").text("R$ " + $(".slider-range-max").slider("value"));
        });

        $(document).ready(function() {
            $(".navigation-menu .menu-item").on("click", function(event) {
                event.preventDefault();
                let menuItemValue = $(this).text();
                let menuToggleButton = $(this)
                    .closest(".menu-filter__item")
                    .find(".menu-toggle-button");
                menuToggleButton.text(menuItemValue);
                $(this)
                    .closest(".navigation-menu")
                    .removeClass("active");
            });

            $(".aplicar").on("click", function(e) {
                e.preventDefault();
                let minAmountValue = $(".minAmount").html();
                let maxAmountValue = $(".maxAmount").html();

                let combinedValue = minAmountValue + " - " + maxAmountValue;

                let menuToggleButton = $(".range");

                menuToggleButton.text(combinedValue);
                $(this)
                    .closest(".navigation-menu")
                    .removeClass("active");
            });

            $(".tab-header-item").on("click", function() {
                $(".range").html(
                    'Valor inicial - final <i class="fa fa-angle-down" aria-hidden="true"></i>'
                );
            });
        });
    }
}
